import capitalize from 'lodash/capitalize';
import { USER_REQUEST, USER_IS_MEDIA_UPLOAD_ALLOWED } from '@/store/actions/user';

const EditProfileForm = () => import('./UserEdit.vue');
const SupportCard = () => import('./SupportCard.vue');
const NavList = () => import('./NavList.vue');
const VideoUsage = () => import('./VideoUsage.vue');

export default {
  name: 'UserProfileLayout',

  components: {
    SupportCard,
    EditProfileForm,
    NavList,
    VideoUsage,
  },

  metaInfo() {
    return {
      title: this.$route.meta.title,
    };
  },

  data() {
    return {
      mobileNav: false,
    };
  },

  mounted() {
    this.$store.dispatch(USER_REQUEST);
    this.$store.dispatch(USER_IS_MEDIA_UPLOAD_ALLOWED);
  },

  computed: {
    hasServicePlan() {
      return this.$store.hasModule('servicePlan');
    },
    sportType() {
      return capitalize(process.env.VUE_APP_SPORT_TYPE);
    },
    navLinks() {
      return [
        {
          title: 'My Profile',
          to: { name: 'profile.my' },
          disabled: !this.$can('edit', 'User'),
          visible: true,
        },
        { divider: true, visible: this.hasServicePlan },
        {
          title: 'Manage Membership',
          to: { name: 'profile.manageMembership' },
          disabled: this.hasServicePlan && !this.$can('edit', 'Subscription'),
          visible: this.hasServicePlan && this.$can('edit', 'Subscription'),
        },
        { divider: true, visible: this.hasServicePlan && this.$can('edit', 'Subscription'), },
        {
          title: 'Invoices',
          to: undefined,
          disabled: true,
          visible: this.hasServicePlan && this.$can('edit', 'Subscription'),
        },
        { divider: true, visible: this.hasServicePlan && this.$can('edit', 'Subscription'), },
        {
          title: 'Privacy',
          to: undefined,
          disabled: true,
          visible: false,
        },
        { divider: true, visible: false, },
        {
          title: 'FAQ',
          to: undefined,
          disabled: true,
          visible: false,
        },
        // { title: '', to: undefined, disabled: false, visible: false },
      ];
    },

    breadcrumbs() {
      let items = [
        {
          text: `FS Cloud ${this.sportType}`,
          disabled: false,
          to: 'players',
        },
      ];
      if (this.$route.name === 'profile.my') {
        items.push({ text: this.$route.meta.title, disabled: true });
      }
      if (this.$route.name === 'profile.manageMembership') {
        items.push({ text: 'My profile', disabled: false, to: { name: 'profile.my' } });
        items.push({ text: this.$route.meta.title, disabled: true });
      }
      return items;
    },
    videoUsage() {
      const filesLeft = this.$store.state.user.mediaUpload?.quota?.filesLeft || 0;
      const maxFiles = this.$store.state.user.mediaUpload?.quota?.maxFiles;

      return {
        current: this.$store.state.user.mediaUpload?.quota?.filesUsed || 0,
        limit: maxFiles === 9999999999 ? '∞' : filesLeft,
      }
    }
  },
};
